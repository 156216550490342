import * as axios from 'axios';

const url = `${process.env.VUE_APP_API_URL}/userStatus`;

export enum OnlineStatus {
    Offline,
    Available,
    Busy
}

export interface UserAvailabilityModel {
    userId: string;
    status: OnlineStatus;
}

export function onlineStatus(userId: string) {
    return axios.default.get<OnlineStatus>(`${url}/onlineStatus/${userId}`);
}

export function onlineStatusBatch(userIds: string[]) {
    return axios.default.post<UserAvailabilityModel[]>(`${url}/onlineStatusBatch`, { data: userIds });
}

export function ping() {
    return axios.default.put<void>(`${url}/ping`);
}