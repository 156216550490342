import { AssistantInfo } from "@/assistants/assistantsWebServices";
import { getInterlocutorId, isMessageAddressedToMe, isConversationWithInterlocutorPageOpen } from "@/messaging/helpers";
import { ConversationListItem, Message } from "@/messaging/messagingWebServices";
import Vue from "vue";
import { MessagingState } from "./types";

export function setConversation(
    state: MessagingState,
    value: {
        conversationId: string,
        isActive?: boolean,
        messages?: Partial<Message>[],
        chargedCoins?: number,
        freeCharactersLeft?: number,
        unreadMessages?: number,
    }
) {
    const conversation = state.conversations[value.conversationId];
    if (conversation) {
        Vue.set(state.conversations, value.conversationId, {
            ...conversation,
            isActive: value.isActive ?? conversation.isActive,
            messages: value.messages ?? conversation.messages,
            chargedCoins: value.chargedCoins ?? conversation.chargedCoins,
            freeCharactersLeft: value.freeCharactersLeft ?? conversation.freeCharactersLeft,
        })
    }
    else {
        Vue.set(state.conversations, value.conversationId, {
            messages: value.messages ?? [],
            isActive: value.isActive ?? false,
            freeCharactersLeft: value.freeCharactersLeft,
            chargedCoins: value.chargedCoins,
            interlocutorInfo: undefined,
        })
    }
}

export function incrementUnreadConversations(state: MessagingState) {
    state.unreadConversations += 1;
}

export function decrementUnreadConversations(state: MessagingState) {
    state.unreadConversations -= 1;
}

export function setNumberOfUnreadConversations(
    state: MessagingState,
    value: number) {
    state.unreadConversations = value;
}

export function setConversationListItems(
    state: MessagingState,
    value: ConversationListItem[]
) {
    state.conversationListItems = value
}

export function updateConversationListItemByMessage(
    state: MessagingState,
    value: Message
) {
    const interlocutorId = getInterlocutorId(value.toUserId, value.fromUserId)
    const conversationListItem = state.conversationListItems.find(x => x.interlocutorId === interlocutorId)

    if (!conversationListItem) {
      return
    }

    conversationListItem!.lastMessageContent = value.content
    conversationListItem!.lastMessageDateSent = value.dateSent
    conversationListItem!.lastMessageIsAttachment = value.isAttachment
    conversationListItem!.lastMessageIsConversationEnding = value.isConversationEnding
    if (isMessageAddressedToMe(value) && !isConversationWithInterlocutorPageOpen(interlocutorId)) {
        conversationListItem!.unreadMessages++
    }
}

export function conversationListItemIsRead(state: MessagingState, value: string) {
    const conversationListItem = state.conversationListItems.filter(x => x.interlocutorId === value)[0]
    if (conversationListItem) {
        conversationListItem.unreadMessages = 0
    }
}

export function setInterlocutorInfo(
    state: MessagingState,
    value: {
        interlocutorId: string;
        info: AssistantInfo;
    }
) {
    const conversation = state.conversations[value.interlocutorId];
    if (conversation) {
        conversation.interlocutorInfo = value.info
    }
}
