import * as axios from 'axios';
import { AdditionalPaymentRequest } from './additionalPaymentRequestWebServices';

const url = `${process.env.VUE_APP_API_URL}`;

export interface User {
    id: string;
    firstName: string;
    lastName: string;
}

export interface ConversationListItem {
    interlocutorId: string;
    interlocutorFirstName: string,
    interlocutorLastName: string,
    interlocutorAvatarId?: string;
    fromUserId: string;
    unreadMessages: number;
    lastMessageContent: string;
    lastMessageDateSent: string;
    lastMessageIsAttachment: boolean;
    lastMessageIsConversationEnding: boolean;
}

export interface ConversationModel {
    messages: Message[];
    activeConversation: ActiveConversationResponse;
}

export interface ActiveConversationUser {
    userId: string;
    freeCharactersLeft: number;
}

export interface ActiveConversationResponse {
    chargedCoins: number;
    isActive: boolean;
    user1: ActiveConversationUser;
    user2: ActiveConversationUser;
}

export interface Message {
    id: string;
    content: string;
    dateSent: string;
    dateRead?: string;
    fromUserId: string;
    toUserId: string;
    attachmentName?: string;
    attachmentBlobName?: string;
    isAttachment: boolean;
    gifUrl: string;
    isGif: boolean;
    additionalPaymentRequestId?: string;
    additionalPaymentRequestStatus?: AdditionalPaymentRequestStatus;
    additionalPaymentRequest: AdditionalPaymentRequest;
    isConversationEnding: boolean;
}

export enum SendMessageStatus {
    NewMessage,
    NewMessageCoveredPartially,
    ClientDoesNotHaveCoins,
    ConversationStarted,
    AssistantHasNotSetHourlyRate,
    ConversationNotStartedAndNoFreeCoins,
}

export enum AdditionalPaymentRequestStatus {
    New,
    Approved,
    Rejected,
    FinalApproved
}

export interface SendMessageResult {
    message: Message;
    activeConversation: ActiveConversationResponse;
    status: SendMessageStatus;
}

export enum ValidateMessageResult {
    Ok = 1,
    MessageContainsContactInformation,
}

export function sendMessage(interlocutorId: string, message: string) {
    return axios.default.post<SendMessageResult>(`${url}/conversations/${interlocutorId}`, { message: message });
}
export function validateMessage(message: string) {
    return axios.default.post<ValidateMessageResult>(`${url}/conversations/validateMessage`, { message: message });
}

export function sendGif(interlocutorId: string, gifUri: string) {
    return axios.default.post<Message>(`${url}/conversations/${interlocutorId}/gif`, { data: gifUri });
}

export function sendAttachments(interlocutorId: string, attachments: File[]) {
    const formData = new FormData();
    attachments.forEach(a => {
        formData.append(a.name, a, a.name);
    });

    return axios.default.post<Message[]>(`${url}/conversations/${interlocutorId}/attachment`, formData, {
        headers: {
            'Content-Type': 'multipart/form-data',
        },
    });
}

export function fetchConversations() {
    return axios.default.get<ConversationListItem[]>(`${url}/conversations`);
}

export function fetchNumberOfUnreadConversations() {
    return axios.default.get<number>(`${url}/conversations/unread-number`);
}

export function fetchConversation(interlocutorId: string) {
    return axios.default.get<ConversationModel>(`${url}/conversations/${interlocutorId}`);
}

export function finishConversation(interlocutorId: string) {
    return axios.default.get<void>(`${url}/conversations/${interlocutorId}/finish`);
}

export function isConversationActive(interlocutorId: string) {
    return axios.default.get<boolean>(`${url}/conversations/${interlocutorId}/is-active`);
}
