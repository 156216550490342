import { ActionContext } from 'vuex';
import { AssistantState } from '..';
import { balance } from '@/serviceClients/userWebServices';

export async function loadBalance(context: ActionContext<AssistantState, AssistantState>) {
    context.state.isBalanceLoading = true;
    balance(context.state.user!.id)
    .then((response) => {
        context.state.balance = response.data;
        // turning off isLoading only on success in this situation, as we are just retrying on rerror
        context.state.isBalanceLoading = false;
    })
    .catch((error) => {
        context.state.balance = NaN;
        setTimeout(() => loadBalance(context), 1000);
    });
  }