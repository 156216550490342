import { JobOfferInterestStatus, UnseenJobOfferInterests } from '@/offers/offerWebServices';
import { ActionContext } from 'vuex';
import { JobOffersState } from '.';

export const setUnseenInterest = (context: ActionContext<JobOffersState, JobOffersState>, value: {
    status: JobOfferInterestStatus,
    number: number,
}) => {
    if (value.status === JobOfferInterestStatus.Accepted) {
        context.state.unseenInterestAccepted = value.number;
    }
    else if (value.status === JobOfferInterestStatus.Declined) {
        context.state.unseenInterestDeclined = value.number;
    }
}

export const incrementUnseenInterest = (context: ActionContext<JobOffersState, JobOffersState>) => (status: JobOfferInterestStatus) => {
    if (status === JobOfferInterestStatus.Accepted) {
        context.state.unseenInterestAccepted += 1;
    }
    else if (status === JobOfferInterestStatus.Declined) {
        context.state.unseenInterestDeclined += 1;
    }
}

export const decrementUnseenInterest = (context: ActionContext<JobOffersState, JobOffersState>) => (status: JobOfferInterestStatus) => {
    if (status === JobOfferInterestStatus.Accepted) {
        context.state.unseenInterestAccepted -= 1;
    }
    else if (status === JobOfferInterestStatus.Declined) {
        context.state.unseenInterestDeclined -= 1;
    }
}