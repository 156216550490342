import Vue from 'vue'
import Vuex, { ActionContext } from 'vuex'
import { createDirectStore } from "direct-vuex"
import { LoggedUserInformation } from '@/login/loginModel'
import { saveToStorage } from '@/helpers/storageHelper'
import { Transaction, TransactionStatus } from '@/serviceClients/userWebServices'
import { loadBalance } from '@/store/actions/loadBalance'
import { messagingModule } from './messaging'
import { jobOffersModule } from './job-offers'
import { getAppName } from '@/helpers/settingsHelper'
import VueI18n from 'vue-i18n'
import { setUserPhotoAsMain } from '@/panel/userPanelWebServices'

Vue.use(Vuex)

export interface AssistantState {
  user: LoggedUserInformation | undefined;
  urlToRedirectAfterLogon: string | undefined;
  assistantSearchUrlToRedirect: string | undefined;
  balance: number | undefined;
  isBalanceLoading: boolean;
  transactions: Transaction[];
  activeModuleName?: string;
}
export const assistantModule = {
  namespaced: true as true,
  state: {
    user: undefined,
    urlToRedirectAfterLogon: undefined,
    assistantSearchUrlToRedirect: undefined,
    conversations: {},
    balance: undefined,
    isBalanceLoading: false,
    transactions: [],
    activeModuleName: getAppName(),
  } as AssistantState,
  getters: {
    user: (state: AssistantState) => state.user,
    urlToRedirectAfterLogon: (state: AssistantState) => state.urlToRedirectAfterLogon,
    assistantSearchUrlToRedirect: (state: AssistantState) => state.assistantSearchUrlToRedirect,
    balance: (state: AssistantState) => state.balance,
    isBalanceLoading: (state: AssistantState) => state.isBalanceLoading,
    transactions: (state: AssistantState) => state.transactions,
    activeModuleName: (state: AssistantState) => state.activeModuleName,
  },
  mutations: {
    setUser(state: AssistantState, user: LoggedUserInformation | undefined) {
      state.user = user
    },
    setUserAvatarId(state: AssistantState, avatarId: string) {
      state.user!.avatarId = avatarId;
    },
    setUrlToRedirectAfterLogon(state: AssistantState, value: string | undefined) {
      state.urlToRedirectAfterLogon = value;
    },
    setAssistantSearchUrlToRedirect(state: AssistantState, value: string | undefined) {
      state.assistantSearchUrlToRedirect = value;
    },
    setBalance(state: AssistantState, balance: number) {
      state.balance = balance;
    },
    setIsBalanceLoading(state: AssistantState, flag: boolean) {
      state.isBalanceLoading = flag;
    },
    setTransactions(state: AssistantState, transactions: Transaction[]) {
      state.transactions = transactions;
    },
    cancelTransaction(state: AssistantState, value: { id: string, comment: string }) {
      const transaction = state.transactions.filter(t => t.id === value.id)[0];
      if (!!transaction) {
        transaction.comment = value.comment;
        transaction.status = TransactionStatus.Cancelled;

        state.transactions = [
          transaction,
          ...state.transactions.filter(t => t.id !== value.id),
        ]
      }
    },
    addTransaction(state: AssistantState, transaction: Transaction) {
      state.transactions.unshift(transaction);
    },
    setActiveModuleName(state: AssistantState, moduleName?: string | VueI18n.TranslateResult) {
      state.activeModuleName = moduleName?.toString();
    }
  },
  actions: {
    saveUserToStorage(context: ActionContext<AssistantState, AssistantState>) {
      saveToStorage('user', context.state.user);
    },
    updateStoredUser(context: ActionContext<AssistantState, AssistantState>, values: Partial<LoggedUserInformation>) {
      Object.assign(context.state.user, values);
      saveToStorage('user', context.state.user);
    },
    clearStoredUser(context: ActionContext<AssistantState, AssistantState>) {
      context.state.user = undefined;
      saveToStorage('user', undefined);
    },
    loadBalance,
    async setPhotoAsMain(context: ActionContext<AssistantState, AssistantState>, photoId: string | undefined) {
      if (photoId) {
        await setUserPhotoAsMain(context.state.user!.id, photoId);
      }
      context.commit('setUserAvatarId', photoId);
      saveToStorage('user', context.state.user);
    }
  }
}

const { store, rootActionContext, moduleActionContext } = createDirectStore({
  modules: {
    module: assistantModule,
    messaging: messagingModule,
    jobOffers: jobOffersModule,
  }
})
// Export the direct-store instead of the classic Vuex store.
export default store
// The following exports will be used to enable types in the
// implementation of actions.
export { rootActionContext, moduleActionContext }
// The following lines enable types in the injected store '$store'.
export type AppStore = typeof store
declare module "vuex" {
  interface Store<S> {
    direct: AppStore
  }
}