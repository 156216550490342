import { AssistantInfo } from "@/assistants/assistantsWebServices";
import { ConversationListItem } from "@/messaging/messagingWebServices";
import { MessagingState } from "./types";

export const isConversationActive = (state: MessagingState) => (interlocutorId: string): boolean => {
    return state.conversations[interlocutorId]?.isActive ?? false
}

export const hasUnreadMessages = (state: MessagingState) => (interlocutorId: string): boolean => {
    const item = state.conversationListItems.find(x => x.interlocutorId === interlocutorId)
    if (!item) {
        return false
    }

    return item.unreadMessages > 0
}

export const interlocutorInfo = (state: MessagingState) => (interlocutorId: string): AssistantInfo | undefined => {
    return state.conversations[interlocutorId]?.interlocutorInfo ?? undefined
}