import store from '@/store'
import { HubConnection, HubConnectionBuilder, LogLevel } from "@microsoft/signalr"
import { configureJobOfferNotifications } from './jobOfferNotifications';
import { configureMessagesNotifications } from './messagesNotifications';
import { configureAdditionalPaymentNotifications } from './paymentRequestsNotifications';
import { configureVideoCallNotifications } from './videoCallNotifications';

let connectionHub: HubConnection | undefined;

let startedPromise = null
export const startSignalR = (vue: Vue): any => {
  connectionHub = new HubConnectionBuilder()
    .withUrl(`${process.env.VUE_APP_API_URL}/conversationsHub?token=${store.getters.module.user!.token}`)
    .configureLogging(LogLevel.Information)
    .build()

  connectionHub.onclose(() => startSignalR(vue));

  startedPromise = connectionHub.start().catch(err => {
    return new Promise((resolve, reject) =>
      setTimeout(() => startSignalR(vue)
        .then(resolve)
        .catch(() => {
          // hacer nada
        }), 500))
  })

  configureMessagesNotifications(connectionHub, vue);
  configureVideoCallNotifications(connectionHub, vue);
  configureAdditionalPaymentNotifications(connectionHub, vue);
  configureJobOfferNotifications(connectionHub, vue);
  return startedPromise
}