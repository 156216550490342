import Vue from 'vue'
import VueRouter, { RouteConfig } from 'vue-router'
import store from '@/store'
import { isAccountSetupRequired, isAccountSetupForbidden } from '@/accountSetup/accountSetupConditions'

Vue.use(VueRouter)

const selectTypeRouteName = 'SelectType';
export const accountSetupRouteName = 'AccountSetup';

const routes: RouteConfig[] = [
  {
    path: '/',
    name: 'Home',
    component: () => import(/* webpackChunkName: "home" */ '../views/Home.vue'),
  },
  {
    path: '/offers',
    name: 'Offers',
    component: () => import(/* webpackChunkName: "account" */ '../views/Offers.vue'),
    children: [
      {
        path: '',
        name: 'Main',
        component: () => import(/* webpackChunkName: "account" */ '../offers/OffersMain.vue')
      },
      {
        path: 'new',
        name: 'New offer',
        component: () => import(/* webpackChunkName: "account" */ '../offers/OfferEditor.vue'),
        meta: {
          requiresAuth: true
        },
      },
      {
        path: ':id/edit',
        name: 'Edit offer',
        component: () => import(/* webpackChunkName: "account" */ '../offers/OfferEditor.vue'),
        meta: {
          requiresAuth: true
        },
      },
    ],
  },
  {
    path: '/offers/:offerId/interests',
    name: 'Offer interests',
    component: () => import(/* webpackChunkName: "account" */ '../offers/interests/OfferInterests.vue'),
    meta: {
      requiresAuth: true
    },
  },
  {
    path: '/invite',
    name: 'Invite',
    component: () => import(/* webpackChunkName: "account" */ '../views/Invite.vue'),
    meta: {
      requiresAuth: true
    },
  },
  {
    path: '/assistants',
    name: 'Assistants',
    component: () => import(/* webpackChunkName: "search" */ '../views/Assistants.vue')
  },
  {
    path: '/category/:id/:name',
    name: 'Category',
    component: () => import(/* webpackChunkName: "search" */ '../views/Category.vue')
  },
  {
    path: '/assistant/:id',
    name: 'Assistant',
    component: () => import(/* webpackChunkName: "assistants" */ '../views/Assistant.vue')
  },
  {
    path: '/kevin',
    name: 'Assistant - Kevin',
    component: () => import(/* webpackChunkName: "assistants" */ '../views/Kevin.vue'),
    
  },
  {
    path: '/assistant/:id/sendMessage',
    name: 'AssistantMessage',
    component: () => import(/* webpackChunkName: "assistants" */ '../views/Assistant.vue')
  },
  {
    path: '/register',
    name: 'Register',
    component: () => import(/* webpackChunkName: "account" */ '../views/Register.vue')
  },
  {
    path: '/register/:invitationId',
    name: 'Register',
    component: () => import(/* webpackChunkName: "account" */ '../views/Register.vue')
  },
  {
    path: '/register/confirm/:id',
    name: 'Confirm Registration',
    component: () => import(/* webpackChunkName: "account" */ '../views/ConfirmRegistration.vue')
  },
  {
    path: '/login',
    name: 'Login',
    component: () => import(/* webpackChunkName: "account" */ '../views/Login.vue')
  },
  {
    path: '/forgotPassword',
    name: 'Forgot password',
    component: () => import(/* webpackChunkName: "account" */ '../views/ForgotPassword.vue')
  },
  {
    path: '/resetPassword/:userId/:requestPasswordResetId',
    name: 'Reset Password',
    component: () => import(/* webpackChunkName: "account" */ '../views/ResetPassword.vue')
  },
  {
    path: '/facebook',
    name: 'Facebook',
    component: () => import(/* webpackChunkName: "account" */ '../views/Facebook.vue')
  },
  {
    path: '/selectType',
    name: selectTypeRouteName,
    component: () => import(/* webpackChunkName: "account" */ '../views/SelectType.vue')
  },
  {
    path: '/accountSetup',
    name: 'AccountSetup',
    component: () => import(/* webpackChunkName: "account" */ '../accountSetup/AccountSetup.vue')
  },
  {
    path: '/panel',
    name: 'Panel',
    meta: {
      requiresAuth: true
    },
    component: () => import(/* webpackChunkName: "account" */ '../views/Panel.vue'),
  },
  {
    path: '/conversations',
    name: 'Conversations',
    meta: {
      requiresAuth: true
    },
    component: () => import(/* webpackChunkName: "conversation" */ '../views/Conversations.vue')
  },
  {
    path: '/conversations/:id',
    name: 'ConversationDetails',
    meta: {
      requiresAuth: true
    },
    component: () => import(/* webpackChunkName: "conversation" */ '../views/Conversations.vue')
  },
  {
    path: '/page/:id',
    name: 'Page',
    meta: {
      requiresAuth: false
    },
    component: () => import(/* webpackChunkName: "page" */ '../views/Page.vue')
  },
  {
    path: '/blog/:id',
    name: 'Blog Post',
    meta: {
      requiresAuth: false
    },
    component: () => import(/* webpackChunkName: "page" */ '../views/BlogPost.vue')
  },
  {
    path: '/blog',
    name: 'Blog',
    meta: {
      requiresAuth: false
    },
    component: () => import(/* webpackChunkName: "page" */ '../views/Blog.vue')
  },
  {
    path: '/search/:phrase',
    name: 'Search',
    meta: {
      requiresAuth: false
    },
    component: () => import(/* webpackChunkName: "search" */ '../views/Search.vue')
  },
  {
    path: '/conversations/call/:videoCallId',
    name: 'Call',
    meta: {
      requiresAuth: true
    },
    component: () => import(/* webpackChunkName: "conversation" */ '../views/Call.vue')
  },
  {
    path: '/transactions',
    name: 'Transactions',
    meta: {
      requiresAuth: true
    },
    component: () => import(/* webpackChunkName: "account" */ '../views/Transactions.vue')
  },
  {
    path: '/verify-skills',
    name: 'Verify skills',
    meta: {
      requiresAuth: true
    },
    component: () => import(/* webpackChunkName: "account" */ '../verifySkills/VerifySkillsMain.vue')
  },
  {
    path: '/howItWorks',
    name: 'HowItWorks',
    meta: {
      requiresAuth: false
    },
    component: () => import(/* webpackChunkName: "howItWorks" */ '../views/HowItWorks.vue')
  },
  {
    path: '/whyJoinUs',
    name: 'WhyJoinUs',
    meta: {
      requiresAuth: false
    },
    component: () => import(/* webpackChunkName: "whyJoinUs" */ '../views/WhyJoinUs.vue')
  },
  {
    path: '/aboutUs',
    name: 'AboutUs',
    meta: {
      requiresAuth: false
    },
    component: () => import(/* webpackChunkName: "aboutUs" */ '../views/AboutUs.vue')
  },
  {
    path: '/partnerProgram',
    name: 'PartnerProgram',
    meta: {
      requiresAuth: false
    },
    component: () => import(/* webpackChunkName: "partnerProgram" */ '../views/PartnerProgram.vue')
  },
  {
    path: '/proMarketing',
    name: 'ProMarketingProgram',
    meta: {
      requiresAuth: false
    },
    component: () => import(/* webpackChunkName: "proMarketingProgram" */ '../views/ProMarketingProgram.vue')
  },
  {
    path: '/support/:aboutUserId?',
    name: 'Support request',
    meta: {
      requiresAuth: false
    },
    component: () => import(/* webpackChunkName: "support" */ '../views/CreateSupportRequest.vue')
  }
]

const router = new VueRouter({
  mode: 'history',
  base: process.env.BASE_URL,
  routes
});

router.beforeEach((to, from, next) => {
  store.commit.module.setActiveModuleName();

  if (store.getters.module.user && !to.matched.some(record => record.name === accountSetupRouteName) && isAccountSetupRequired()) {
    next({ name: accountSetupRouteName });
    return;
  }

  if (store.getters.module.user && to.matched.some(record => record.name === accountSetupRouteName) && isAccountSetupForbidden()) {
    next({ name: "Home" });
    return;
  }

  if (store.getters.module.user && to.matched.some(record => record.path.includes('register'))) {
    next({ name: 'Panel' });
    return;
  }

  if (to.matched.some(record => record.meta.requiresAuth) && !store.getters.module.user) {
    store.commit.module.setUrlToRedirectAfterLogon(
      to.path
    );
    next({ name: 'Login' });
  } else {
    next();
  }
})

export default router
