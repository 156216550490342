import {
    incrementUnseenInterest,
    decrementUnseenInterest,
    setUnseenInterest,
} from './actions';

export interface JobOffersState {
    unseenInterestAccepted: number;
    unseenInterestDeclined: number;
}

export const jobOffersModule = {
    namespaced: true as true,
    state: {
        unseenInterestAccepted: 0,
        unseenInterestDeclined: 0,
    } as JobOffersState,
    getters: {
        unseenInterestAccepted: (state: JobOffersState) => state.unseenInterestAccepted,
        unseenInterestDeclined: (state: JobOffersState) => state.unseenInterestDeclined,
        totalUnseen: (state: JobOffersState) => state.unseenInterestAccepted + state.unseenInterestDeclined,
    },
    mutations: {},
    actions: {
        setUnseenInterest,
        incrementUnseenInterest,
        decrementUnseenInterest,
    },
}