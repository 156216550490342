import { AccountType } from '@/register/registerModel';
import store from '@/store';
import { ActiveConversationResponse, ActiveConversationUser, Message } from './messagingWebServices';
import { vue } from '@/main';
import Constants from '@/Constants';

export const userHasCoins = () => {
    return store.getters.module.balance! > 0
}

export const userHasFreeCharacters = (interlocutorId: string) => {
    return store.state.messaging.conversations[interlocutorId].freeCharactersLeft > 0;
}

export const userCanConverse = (interlocutorId: string) => {
    if(Constants.IsSupportUserId(interlocutorId)) return true;
    if(Constants.IsSupportUserId(store.getters.module.user!.id)) return true;

    const conversation = store.state.messaging.conversations[interlocutorId];
    if (!conversation) {
        return true;
    }

    if (store.getters.module.user!.accountType === AccountType.Assistant) {
        return store.state.messaging.conversations[interlocutorId].isActive || userHasFreeCharacters(interlocutorId);
    }

    return userHasCoins() || userHasFreeCharacters(interlocutorId);
}

export function getMe(activeConversation: ActiveConversationResponse): ActiveConversationUser {
    return store.getters.module.user!.id === activeConversation.user1.userId ? activeConversation.user1 : activeConversation.user2;
}

export function getInterlocutorId(userId1: string, userId2: string) {
    const userId = store.getters.module.user!.id;
    return userId === userId1 ? userId2 : userId1;
}

export function getUserId(userId1: string, userId2: string) {
    const userId = store.getters.module.user!.id;
    return userId === userId1 ? userId1 : userId2;
}

export function isMessageAddressedToMe(message: Message): boolean {
    return message.toUserId === store.getters.module.user!.id
}

export function isConversationsPageOpen(): boolean {
    return vue.$router.currentRoute.path.includes('/conversations')
}

export function isConversationWithInterlocutorPageOpen(interlocutorId: string): boolean {
    return vue.$router.currentRoute.path.includes(`/conversations/${interlocutorId}`)
}
