import { Message, SendMessageResult } from '@/messaging/messagingWebServices';
import store from '@/store';
import { HubConnection } from '@microsoft/signalr';
import { handleNewSimpleMessage, handleNewPaymentReqestMessage, handleSendMessageResult } from '@/messaging/messagingHandlers';
import { getInterlocutorId } from '@/messaging/helpers';

export const configureMessagesNotifications = (connection: HubConnection, vue: Vue) => {
    connection.on('MessageReceived', async (result: SendMessageResult) => {
        await handleSendMessageResult(result);
    });

    connection.on('AttachmentReceived', async (result: Message[]) => {
        await handleNewSimpleMessage(result);
    })

    connection.on('GifReceived', async (result: Message) => {
        await handleNewSimpleMessage([result]);
    })

    connection.on('PaymentRequestMessageReceived', async (result: Message) => {
        await handleNewPaymentReqestMessage(result)
    });

    connection.on('ConversationFinished', async (clientId, assistantId) => {
        await store.dispatch.messaging.finishConversation({ interlocutorId: getInterlocutorId(clientId, assistantId) })
    });

    connection.on('AssistantModifiedHourlyRate', async (conversationId, assistantFullName) => {
        const messageContent = vue.$t('Conversation with the assistant {0} has ended, because hourly rate has changed.', [assistantFullName]).toString();
        await store.dispatch.messaging.finishConversation({ interlocutorId: conversationId })
        vue.$toasted.info(messageContent, { duration: 5000 })
    });
}
