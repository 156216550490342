import { LanguageType } from '@/core/translate/translateTypes';

export default interface RegisterModel {
    password: string;
    confirmPassword: string;
    email: string;
    firstName: string;
    lastName: string;
    accountType: AccountType | undefined,
    language: LanguageType,
    referralUserId?: string;
}

export enum AccountType {
    Assistant,
    Client,
    Unknown
}