import { ActionContext } from 'vuex';
import { ActiveConversationResponse, fetchConversation, fetchConversations, Message } from '@/messaging/messagingWebServices';
import { MessagingState } from './types';
import { getInterlocutorId, getMe, isMessageAddressedToMe, isConversationWithInterlocutorPageOpen, isConversationsPageOpen } from '@/messaging/helpers';
import { getAssistantInformation } from '@/assistants/assistantsWebServices';
import { pushError } from '@/core';
import { vue } from '@/main';

export function startConversation(
    context: ActionContext<MessagingState, MessagingState>,
    payload: { interlocutorId: string }
) {
    context.commit('setConversation', {
        conversationId: payload.interlocutorId,
        isActive: true,
        chargedCoins: 0,
    })
}

export function finishConversation(
    context: ActionContext<MessagingState, MessagingState>,
    payload: { interlocutorId: string }
) {
    context.commit('setConversation', {
        conversationId: payload.interlocutorId,
        isActive: false,
    })
}

export async function handleNewMessage(
    context: ActionContext<MessagingState, MessagingState>,
    payload: { message: Message }
) {
    const interlocutorId = getInterlocutorId(payload.message.fromUserId, payload.message.toUserId)
    if (isMessageAddressedToMe(payload.message) && !isConversationWithInterlocutorPageOpen(interlocutorId)) {
        context.commit('incrementUnreadConversations')
    }

    const conversation = context.state.conversations[interlocutorId];
    if (conversation) {
        if (isMessageAddressedToMe(payload.message)) {
            conversation.messages.push(payload.message);
        }
    } else {
        context.commit('setConversation', {
            conversationId: interlocutorId,
            messages: [payload.message],
        })
    };

    const conversationListItem = context.state.conversationListItems.find(x => x.interlocutorId === interlocutorId)
    if (!conversationListItem) {
        await loadConversationListItems(context)
    }

    if (isConversationsPageOpen()) {
        context.commit('updateConversationListItemByMessage', payload.message)
    }
}

export async function addTempSentMessage(
    context: ActionContext<MessagingState, MessagingState>,
    payload: { message: Message }
) {
    const interlocutorId = getInterlocutorId(payload.message.fromUserId, payload.message.toUserId)
    const conversation = context.state.conversations[interlocutorId];
    conversation.messages.push(payload.message);
    context.commit('updateConversationListItemByMessage', payload.message)
}

export async function updateActiveConversation(
    context: ActionContext<MessagingState, MessagingState>,
    payload: { activeConversation: ActiveConversationResponse }
) {
    const interlocutorId = getInterlocutorId(payload.activeConversation.user1.userId, payload.activeConversation.user2.userId)
    context.commit('setConversation', {
        conversationId: interlocutorId,
        chargedCoins: payload.activeConversation.chargedCoins,
        freeCharactersLeft: getMe(payload.activeConversation).freeCharactersLeft,
    })
}

export async function loadConversation(
    context: ActionContext<MessagingState, MessagingState>,
    payload: { interlocutorId: string }
) {
    const fetchData = async () => {
        try {
            const res = await Promise.all([
                fetchConversation(payload.interlocutorId),
                getAssistantInformation(payload.interlocutorId),
            ])

            return res
        } catch (error) {
            pushError((error as any).response?.data?.error || error, { title: vue.$t("An error occurred while fetching data") });
            throw error;
        }
    }

    const [conversationResult, interlocutorInfo] = await fetchData()

    context.commit('setConversation', {
        conversationId: payload.interlocutorId,
        messages: conversationResult.data.messages.sort(
            (a, b) => new Date(a.dateSent).getTime() - new Date(b.dateSent).getTime()
        ),
        isActive: conversationResult.data.activeConversation.isActive,
        chargedCoins: conversationResult.data.activeConversation.chargedCoins,
        freeCharactersLeft: getMe(conversationResult.data.activeConversation).freeCharactersLeft,
        unreadMessages: 0,
    });

    if (context.getters.hasUnreadMessages(payload.interlocutorId)) {
        context.commit('conversationListItemIsRead', payload.interlocutorId)
        context.commit('decrementUnreadConversations')
    }

    context.commit('setInterlocutorInfo', {
        interlocutorId: payload.interlocutorId,
        info: interlocutorInfo.data,
    })
}

export async function loadConversationListItems(context: ActionContext<MessagingState, MessagingState>) {
    const result = await fetchConversations()
    context.commit('setConversationListItems', result.data)
}