import * as actions from './actions'
import * as mutations from './mutations'
import * as getters from './getters'
import { MessagingState } from './types';

export const messagingModule = {
    namespaced: true as true,
    state: {
        conversations: {},
        unreadConversations: 0,
        conversationListItems: [],
    } as MessagingState,
    getters: { ...getters },
    mutations: { ...mutations },
    actions: { ...actions },
}