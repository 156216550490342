import { EducationLevel, Gender, MaritalStatus } from '@/accountSetup/accountSetupService';
import { City } from '@/serviceClients/regionWebService';
import * as axios from 'axios';

const url = `${process.env.VUE_APP_API_URL}/user`;

export interface Address {
    userId: string;
    id: string;
    street: string;
    additionalInfo: string;
    city: City | null;
    country: string;
    zipCode: string;
    longitude: number | null;
    latitude: number | null;
}

export interface UserBasicInfo {
    firstName: string,
    lastName: string;
    gender: Gender;
    maritalStatus?: MaritalStatus;
    height?: number;
    description?: string;
    birthDate?: Date;
    educationLevel?: EducationLevel;
    educationDetails?: string
}

export function fetchAddresses(userId: string) {
    return axios.default.get<Address[]>(`${url}/${userId}/addresses`);
}

export function deleteUserPhoto(userId: string, id: string) {
    return axios.default.delete(`${url}/${userId}/photos/${id}`);
}

export function setUserPhotoAsMain(userId: string, id: string) {
    return axios.default.post(`${url}/${userId}/photos/${id}/set-main`);
}

export function updateAddress(userId: string, address: Address) {
    return axios.default.put(`${url}/${userId}/addresses`, address);
}

export function createAddress(userId: string, address: Address) {
    return axios.default.post(`${url}/${userId}/addresses`, address);
}

export function updateBasicInfo(userId: string, info: UserBasicInfo) {
    return axios.default.put(`${url}/${userId}/basicInfo`, info);
}

export function fetchInvoiceDetails(userId: string) {
    return axios.default.get<string>(`${url}/${userId}/invoiceDetails`);
}

export function setInvoiceDetails(userId: string, invoiceDetails: string) {
    return axios.default.put(`${url}/${userId}/invoiceDetails`, { data: invoiceDetails });
}