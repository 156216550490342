import { vue } from '@/main';
import store from '@/store';
import {
    getInterlocutorId,
    getUserId,
    isConversationsPageOpen,
    isMessageAddressedToMe,
    isConversationWithInterlocutorPageOpen
} from './helpers';
import {
    ActiveConversationResponse,
    Message,
    SendMessageResult,
    SendMessageStatus
} from './messagingWebServices';


async function showToastOnNewMessage(message: Message) {
    vue.$toasted.info(
        vue.$t('You have new message').toString(),
        {
            duration: 5000,
            action: {
                text: vue.$t('Read').toString(),
                onClick: async (_, toastObject) => {
                    await vue.$router.push(`/conversations/${getInterlocutorId(message.fromUserId, message.toUserId)}`)
                    toastObject.goAway();
                }
            }
        });
}

async function notifyAboutNewMessage(message: Message) {
    if (!isConversationsPageOpen()) {
        await showToastOnNewMessage(message)
    }

    if (isMessageAddressedToMe(message) && !isConversationWithInterlocutorPageOpen(getUserId(message.fromUserId, message.toUserId))) {

        const newMessageSound = new Audio(require('../assets/audio/new_message.ogg'))
        newMessageSound.play().catch()
    }
}

async function handleNewMessage(message: Message, activeConversation: ActiveConversationResponse) {
    await notifyAboutNewMessage(message);

    await store.dispatch.messaging.handleNewMessage({ message })
    await store.dispatch.messaging.updateActiveConversation({ activeConversation })
    await store.dispatch.module.loadBalance()
}

export async function handleNewSimpleMessage(messages: Message[]) {
    if (messages && messages.length > 0) {
        messages.forEach(async m => {
            await store.dispatch.messaging.handleNewMessage({ message: m })
        });
    }
}

export async function handleNewPaymentReqestMessage(message: Message) {
    await notifyAboutNewMessage(message);

    await store.dispatch.messaging.handleNewMessage({ message })
}

export async function handleSendMessageResult(result: SendMessageResult) {
    const interlocutorId = getInterlocutorId(result.message.fromUserId, result.message.toUserId)
    switch (result.status) {
        case SendMessageStatus.NewMessageCoveredPartially:
            if (isConversationWithInterlocutorPageOpen(interlocutorId)) {
                vue.$toasted.info(vue.$t('Client ran out of funds. Conversation finished').toString(), { duration: 5000 })
            }
            await handleNewMessage(result.message, result.activeConversation);
            break;
        case SendMessageStatus.ClientDoesNotHaveCoins:
        case SendMessageStatus.ConversationNotStartedAndNoFreeCoins:
            vue.$toasted.error(vue.$t('Client does not have sufficient funds. Message has not been sent or has been sent partially.').toString(), { duration: 5000 });
            await store.dispatch.messaging.finishConversation({ interlocutorId })
            break;
        case SendMessageStatus.NewMessage:
            await handleNewMessage(result.message, result.activeConversation);
            break;
        case SendMessageStatus.ConversationStarted:
            await store.dispatch.messaging.startConversation({ interlocutorId })
            await handleNewMessage(result.message, result.activeConversation);
            break;
    }
}
