import * as axios from 'axios';
import { AccountType } from '@/register/registerModel';
import { LanguageType } from '@/core/translate/translateTypes';
import { AdditionalPaymentRequest } from '@/messaging/additionalPaymentRequestWebServices';
import store from '@/store';
import { UserRatingInfo } from '@/assistants/assistantsWebServices';

const url = `${process.env.VUE_APP_API_URL}/user`;


export enum TransactionType {
    OnlineTopUp,
    OfflineTopUp,
    PayOut,
    Payment,
    Charge
}

export enum TransactionStatus {
    Successful,
    Pending,
    Cancelled,
    Error
}

export interface User {
    id: string;
    firstName: string;
    lastName: string;
}

export interface VideoCall {
    fromUser: User;
    toUser: User;
}

export interface Transaction {
    id: string;
    amount: number;
    type: TransactionType;
    transactionDateTime: string;
    comment: string;
    status: TransactionStatus;
    invoiceGeneration?: InvoiceGeneration;
    interlocutor?: User;
    videoCall?: VideoCall;
    additionalPaymentRequest?: AdditionalPaymentRequest;
}

export type InvoiceGeneration = {
    id: string;
    invoiceNumber: string;
    invoiceFileName: string;
}

export type UserPhoto = {
    id: string;
    userId: string;
    dateAdded: Date;
    isMainPhoto: boolean;
}
export interface Currency {
  code: string,
  symbol: string;
  ratePerUsd: number;
  allowInPayout: boolean;
}

export interface CreatePaymentTransactionRequest {
    coins: number;
    price: number;
    currency: Currency;
    topUpTransactionType: TopUpTransactionType;
    bankTransferHtml?: string;
}

export enum TopUpTransactionType
{
    Business,
    Entertainment
}


export function setAccountType(userId: string, accountType: AccountType) {
    return axios.default.put<void>(`${url}/${userId}/setAccountType`, { data: accountType });
}

export function balance(userId: string) {
    return axios.default.get<number>(`${url}/${userId}/balance`);
}

export function fetchTransactions(userId: string) {
    return axios.default.get<Transaction[]>(`${url}/${userId}/transactions`);
}

export function getPhotos(userId: string) {
    return axios.default.get<UserPhoto[]>(`${url}/${userId}/photos`)
}

export function rateUser(userId: string, rating: number) {
    return axios.default.put<void>(`${url}/${userId}/rate`, { rating })
}

export function getMyRating() {
    return axios.default.get<UserRatingInfo>(`${url}/${store.getters.module.user!.id}/rate/mine`);
}

export function setUserLanguage(userId: string, language: LanguageType) {
    return axios.default.put<void>(`${url}/${userId}/language`, { data: language });
}


export function createPaypalTransaction(userId: string, request: CreatePaymentTransactionRequest) {
    return axios.default.put<Transaction>(`${url}/${userId}/createPaypalTransaction`, request);
}

export function setPaypalTransactionSuccess(userId: string, transactionId: string) {
    return axios.default.put<void>(`${url}/${userId}/transactions/${transactionId}/success`);
}

export function setPaypalTransactionError(userId: string, transactionId: string, error: string) {
    return axios.default.put<void>(`${url}/${userId}/transactions/${transactionId}/error`, { data: error });
}

export function createOfflineTransaction(userId: string, request: CreatePaymentTransactionRequest) {
    return axios.default.put<Transaction>(`${url}/${userId}/createOfflineTransaction`, request);
}