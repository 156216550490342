import { IncomingCallModel } from '@/messaging/videoCallWebServices'
import { HubConnection } from '@microsoft/signalr'

export const configureVideoCallNotifications = (connection: HubConnection, vue: Vue) => {
    connection.on('NewCall', (call: IncomingCallModel) => {
        const incomingCallSound = new Audio(require('../assets/audio/incoming_call.ogg'))
        const interval = setInterval(() => incomingCallSound.play().catch(), 2000)

        vue.$bvModal.msgBoxConfirm(`Incoming call from ${call.fromUser.firstName} ${call.fromUser.lastName}`, {
            okTitle: vue.$t('Accept').toString(),
            cancelTitle: vue.$t('Cancel').toString()
        }).then(value => {
            if (value) {
                return vue.$router.push(`/conversations/call/${call.videoCallId}`)
            }
        }).finally(() => {
            if (interval !== null) {
                incomingCallSound.pause()
                clearInterval(interval)
            }
        }).catch()
    })
}