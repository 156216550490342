import { HubConnection } from '@microsoft/signalr';

export const configureJobOfferNotifications = (connection: HubConnection, vue: Vue) => {
    connection.on('JobInterestStatusChanged', () => {
        vue.$toasted.info(vue.$t('Your job interest has been responded').toString(), { duration: 5000 })
    });

    
    connection.on('JobInterestSent', () => {
        vue.$toasted.info(vue.$t('Assistant expressed an interest with your job offer.').toString(), { duration: 5000 })
    });
}