import { City, Region } from '@/serviceClients/regionWebService';
import * as axios from 'axios';

const url = `${process.env.VUE_APP_API_URL}/joboffer`;
const urlInterests = `${process.env.VUE_APP_API_URL}/jobofferinterest`;

export interface JobOfferViewModel {
    id: string;
    userId: string;
    userFirstName: string;
    userLastName: string;
    userPhotoId: string | undefined;
    dateAdded: string;
    expirationDate: string;
    description: string;
    headline: string;
    categoryId: string;
    categoryName: string;
    language: string;
    interestStatus: JobOfferInterestStatus | undefined;
    newAssistantInterestsNumber: number | undefined;
    totalInterestsNumber: number;
    region: Region;
    city: City;
    countryCode: string;
    regionId: string | null;
    cityId: string | null;
}

export interface JobOfferSaveRequest {
    headline: string;
    categoryId: string;
    language: string;
    cityId: string | null;
    regionId: string | null;
    countryCode: string | null;
}

export interface JobOfferInterestModel {
    id: string;
    userId: string;
    userFirstName: string;
    userLastName: string;
    userDescription: string;
    status: JobOfferInterestStatus;
    userPhotoId: string;
}

export interface JobOfferInterestsModel {
    offerId: string;
    headline: string;
    description: string;
    interests: JobOfferInterestModel[];
}

export enum JobOfferInterestStatus {
    Sent = 1,
    Accepted = 2,
    Declined = 3,
}

export interface UnseenJobOfferInterests {
    accepted: number,
    declined: number,
}

export function getOffer(id: string) {
    return axios.default.get<JobOfferViewModel>(`${url}/${id}`);
}

export function getOfferList() {
    return axios.default.get<JobOfferViewModel[]>(url);
}

export function addOffer(model: JobOfferSaveRequest) {
    return axios.default.post<string>(`${url}`, model);
}

export function editOffer(id: string, model: JobOfferSaveRequest) {
    return axios.default.put(`${url}/${id}`, model);
}

export function deleteOffer(id: string) {
    return axios.default.delete(`${url}/${id}`);
}

export const getOfferInterests = (id: string) => {
    return axios.default.get<JobOfferInterestsModel>(`${urlInterests}/${id}`);
}

export const sendOfferInterest = (id: string, assistantId: string) => {
    return axios.default.post<string>(`${urlInterests}/${id}`, {
        data: assistantId
    });
}

export const acceptOfferInterest = (offerId: string, interestId: string) => {
    return axios.default.post<string>(`${urlInterests}/${offerId}/${interestId}/accept`);
}

export const declineOfferInterest = (offerId: string, interestId: string) => {
    return axios.default.post<string>(`${urlInterests}/${offerId}/${interestId}/decline`);
}

export const getNumberOfUnseen = () => {
    return axios.default.get<UnseenJobOfferInterests>(`${urlInterests}/unseen-number`);
}

export const markAsSeen = (status: JobOfferInterestStatus) => {
    return axios.default.post<void>(`${urlInterests}/mark-as-seen`, { data: status });
}
